import axios from "axios";
import React, { useContext, useState } from "react";
import "react-toastify/dist/ReactToastify.css";
const AppContext = React.createContext();

const AppProvider = ({ children }) => {
  const baseUrl = "https://api.cryptomax.expert/api/v1";

  const [userDetails, setUserDetails] = useState([]);
  const [userLoading, setUserLoading] = useState(false);
  const [method, setMethod] = useState([]);
  const [btcLoading, setBtcLoading] = useState(false);

  const getUser = (token) => {
    setUserLoading(true);
    axios
      .get(`${baseUrl}/users/user-info`, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then((data) => {
        if (data.status === 200) {
          setUserLoading(false);
          setUserDetails(data.data);
          sessionStorage.setItem("user", JSON.stringify(data.data));
        }
      })
      .catch((error) => {});
  };

  const getAddress = (token) => {
    axios
      .get(`${baseUrl}/admin-wallets`, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then((data) => {
        if (data.status === 200) {
          setMethod(data.data);
        }
      })
      .catch((error) => {});
  };
  return (
    <AppContext.Provider
      value={{
        baseUrl,
        setUserDetails,
        userDetails,
        getUser,
        userLoading,
        method,
        setMethod,
        getAddress,
        btcLoading,
        setBtcLoading,
      }}
    >
      {children}
    </AppContext.Provider>
  );
};

const useGlobalContext = () => {
  return useContext(AppContext);
};

export { useGlobalContext, AppProvider };
