import React from "react";
import { Route, Routes, useLocation } from "react-router-dom";
import "./Dashboard.css";
import Wallet from "../Wallet/Wallet";
import DashNavigation from "../DashNavigation/DashNavigation";
import Deposit from "../Deposit/Deposit";
import Account from "../Account/Account";
import Settings from "../Settings/Settings";
import Transaction from "../Transaction/Transaction";
import Withdraw from "../Withdraw/Withdraw";
import UserPrivateRoute from "../../../utils/UserPrivateRoute";

const Dashboard = () => {
  const { pathname } = useLocation();

  return (
    <section className="dashboard">
      <DashNavigation pathname={pathname} />
      <div className="path">
        <Routes>
          <Route element={<UserPrivateRoute />}>
            <Route element={<Wallet />} path="/wallet" />
            <Route element={<Deposit />} path="/deposit" />
            <Route element={<Account />} path="/account" />
            <Route element={<Settings />} path="/settings" />
            <Route element={<Transaction />} path="/transactions" />
            <Route element={<Withdraw />} path="/withdrawal" />
          </Route>
        </Routes>
      </div>
    </section>
  );
};

export default Dashboard;
