import React from "react";
import "./Transaction.css";
import DashboardHeader from "../DashboardHeader/DashboardHeader";
import { useState } from "react";
import axios from "axios";
import { useGlobalContext } from "../../../context/context";
import { useEffect } from "react";
import moment from "moment";
import { Button } from "../../../components";

const Transaction = () => {
  const [depositHistory, setDepositHistory] = useState([]);
  const [withdrawHistory, setWithdrawHistory] = useState([]);
  let token = JSON.parse(sessionStorage.getItem("token"));
  const { baseUrl, userDetails, getUser } = useGlobalContext();
  const { firstName } = userDetails;
  const symbol = "$";

  const getAllDeposits = () => {
    axios
      .get(`${baseUrl}/deposits`, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then((data) => {
        setDepositHistory(data.data);
      })
      .catch((error) => {});
  };
  const getAllWithdrawals = () => {
    axios
      .get(`${baseUrl}/withdrawals`, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then((data) => {
        setWithdrawHistory(data.data);
      })
      .catch((error) => {});
  };

  useEffect(() => {
    getAllDeposits();
    getAllWithdrawals();
    getUser(token);
  }, []);

  return (
    <section>
      <DashboardHeader pathname={firstName} />
      <div className="transaction_sect">
        <h2>All Deposit History</h2>
        <div className="transaction_table">
          <div className="transaction_table_head">
            <div className="date">
              <h3>Date/Time</h3>
            </div>
            <div className="type">
              <h3>Type</h3>
            </div>
            <div className="amount">
              <h3>Amount</h3>
            </div>
            <div className="status">
              <h3>Status</h3>
            </div>
            <div className="reference">
              <h3>Reference</h3>
            </div>
          </div>
          {depositHistory.map((item, index) => {
            const { createdAt, ref, status, type, amount } = item;

            return (
              <div key={index} className="transaction_table_body">
                <div className="date">
                  <p>{moment(createdAt).format("MMMM Do YYYY, h:mm")}</p>
                </div>
                <div className="type">
                  <p>{type}</p>
                </div>
                <div className="amount">
                  <p>
                    {symbol}
                    {amount}
                  </p>
                </div>
                <div className="status">
                  <Button
                    title={status}
                    width={100}
                    height={30}
                    background={
                      status === "pending"
                        ? "#FFF3E7"
                        : status === "approved"
                        ? "#EDFFF9"
                        : ""
                    }
                    color={
                      status === "pending"
                        ? "#999DA1"
                        : status === "approved"
                        ? "#27AE61"
                        : "#FC6121"
                    }
                  />
                </div>
                <div className="reference">
                  <p>{ref}</p>
                </div>
              </div>
            );
          })}
        </div>
      </div>
      <div className="transaction_sect">
        <h2>All Withdrawal History</h2>
        <div className="transaction_table">
          <div className="transaction_table_head">
            <div className="date">
              <h3>Date/Time</h3>
            </div>
            <div className="type">
              <h3>Type</h3>
            </div>
            <div className="amount">
              <h3>Amount</h3>
            </div>
            <div className="status">
              <h3>Status</h3>
            </div>
            <div className="reference">
              <h3>Reference</h3>
            </div>
          </div>
          {withdrawHistory.map((item, index) => {
            const { createdAt, ref, status, accountNumber, amount } = item;
            return (
              <div key={index} className="transaction_table_body">
                <div className="date">
                  <p>{moment(createdAt).format("MMMM Do YYYY, h:mm")}</p>
                </div>
                <div className="type">
                  <p>{accountNumber}</p>
                </div>
                <div className="amount">
                  <p>
                    {symbol}
                    {amount}
                  </p>
                </div>
                <div className="status">
                  <Button
                    title={status}
                    width={100}
                    height={30}
                    background={
                      status === "pending"
                        ? "#FFF3E7"
                        : status === "approved"
                        ? "#EDFFF9"
                        : ""
                    }
                    color={
                      status === "pending"
                        ? "#999DA1"
                        : status === "approved"
                        ? "27AE61"
                        : "#FC6121"
                    }
                  />
                </div>
                <div className="reference">
                  <p>{ref}</p>
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </section>
  );
};

export default Transaction;
