import React, { useState } from "react";
import { FaTimes } from "react-icons/fa";
import { useNavigate, Link } from "react-router-dom";
import { useForm } from "react-hook-form";
import axios from "axios";
import { ColorRing } from "react-loader-spinner";
import { useGlobalContext } from "../context/context";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const Login = () => {
  const navigate = useNavigate();
  const { baseUrl, getUser } = useGlobalContext();
  const [loading, setLoading] = useState(false);

  const {
    handleSubmit,
    register,
    formState: { errors },
  } = useForm();

  const getLoginDetails = async (data) => {
    setLoading(true);
    axios
      .post(`${baseUrl}/auth/login`, data)
      .then((data) => {
        if (data.status === 200) {
          setLoading(false);
          sessionStorage.setItem("token", JSON.stringify(data.data.token));
          getUser(data.data.token);
          navigate("/wallet");
        }
      })
      .catch((error) => {
        setLoading(false);
        if (error?.response?.data.msg === "Email not registered") {
          toast.error("Email not registered");
        } else {
          toast.error("Incorrect Password");
        }
      });
  };

  return (
    <>
      <div className="parent-con">
        <div className="login-con">
          <Link className="link" to="/">
            <FaTimes color="#001e88" />
          </Link>
          <h3>Login in</h3>
          <p>
            Don't have an account?
            <Link className="reset_text" to="/auth/register">
              Create your account
            </Link>
            , it takes less than a minute.
          </p>
          {/* Login Form */}
          <form
            className="login-form"
            onSubmit={handleSubmit((data) => getLoginDetails(data))}
          >
            <div>
              <p>Email</p>
              <input
                type="email"
                {...register("email", { required: "Email is required" })}
              />
              <p className="login-error-message">{errors.email?.message}</p>
            </div>
            <div>
              <p>Password</p>
              <input
                type="password"
                {...register("password", {
                  required: "Password is required",
                })}
              />
              <p className="login-error-message">{errors.password?.message}</p>
            </div>
            <button className="btn">
              <span>Login</span>
              {loading && (
                <ColorRing
                  visible={true}
                  height="40"
                  width="40"
                  ariaLabel="blocks-loading"
                  wrapperStyle={{}}
                  wrapperClass="blocks-wrapper"
                  colors={["#fff", "#fff", "#fff", "#fff", "#fff"]}
                />
              )}
            </button>
          </form>
          <p style={{ marginTop: 20 }}>
            Forget Password? <span> </span>
            <Link to={`/auth/reset-password`} className="reset_text">
              Reset Password
            </Link>
          </p>
          <ToastContainer />
        </div>
      </div>
    </>
  );
};

export default Login;
