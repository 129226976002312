import { Route, Routes, useLocation } from "react-router-dom";

import {
  About,
  Dashboard,
  Exchange,
  ForgetPassword,
  Home,
  Login,
  Proof,
  Register,
  ResetPassword,
} from "./apps";
import { useEffect } from "react";
import { Privacy, Terms } from "./components";

function App() {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);
  return (
    <>
      <Routes>
        <Route element={<Home />} path="/" />
        <Route element={<Exchange />} path="/exchange" />
        <Route element={<About />} path="/about" />
        <Route element={<Proof />} path="/proof-of-reserve" />
        <Route element={<Login />} path="/auth/login" />
        <Route element={<Register />} path="/auth/register" />
        <Route element={<ResetPassword />} path="/auth/reset-password" />
        <Route element={<ForgetPassword />} path="/auth/change-password" />
        <Route element={<Terms />} path="/terms" />
        <Route element={<Privacy />} path="/privacy" />
        <Route element={<Dashboard />} path="*" />
      </Routes>
    </>
  );
}

export default App;
