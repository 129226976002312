import React from "react";
import "./ProfileDetails.css";
import { useGlobalContext } from "../../../context/context";
import { useEffect } from "react";

const ProfileDetails = () => {
  const { userDetails, getUser } = useGlobalContext();
  let token = JSON.parse(sessionStorage.getItem("token"));

  const {
    firstName,
    lastName,
    email,
    gender,
    dob,
    maritalStatus,
    city,
    country,
    phone,
    address,
  } = userDetails;

  useEffect(() => {
    getUser(token);
  }, [userDetails]);

  return (
    <section className="profile_details">
      <h2>Profile</h2>
      <div className="profile_view">
        <div>
          <h3>First Name</h3>
          <p>{firstName} </p>
        </div>
        <div>
          <h3>Last Name</h3>
          <p>{lastName} </p>
        </div>
        <div>
          <h3>Email</h3>
          <p>{email}</p>
        </div>
        <div>
          <h3>City</h3>
          <p>{city ? city : "....."}</p>
        </div>
        <div>
          <h3>Country</h3>
          <p>{country}</p>
        </div>
        <div>
          <h3>Phone</h3>
          <p>{phone}</p>
        </div>
        <div>
          <h3>Gender</h3>
          <p>{gender ? gender : "....."}</p>
        </div>
        <div>
          <h3>Marital Status</h3>
          <p>{maritalStatus ? maritalStatus : "....."}</p>
        </div>
        <div>
          <h3>Address</h3>
          <p>{address ? address : "....."}</p>
        </div>
        <div>
          <h3>Date of Birth</h3>
          <p>{dob ? dob : "....."}</p>
        </div>
        <div>
          <h3>Currency</h3>
          <p>$</p>
        </div>
      </div>
    </section>
  );
};

export default ProfileDetails;
