import React, { useEffect, useState } from "react";
import DashboardHeader from "../DashboardHeader/DashboardHeader";
import { Button } from "../../../components";
import copy from "copy-to-clipboard";
import "./Deposit.css";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { ColorRing } from "react-loader-spinner";
import { useGlobalContext } from "../../../context/context";
import axios from "axios";
import { useNavigate } from "react-router-dom";

function Deposit() {
  const [payment, setPayment] = useState(null);
  const [loader, setLoader] = useState(false);
  const { baseUrl, method, getAddress, userDetails } = useGlobalContext();
  const token = JSON.parse(sessionStorage.getItem("token"));
  const [proof, setProof] = useState(null);
  const [amount, setAmount] = useState("");
  const [address, setAddress] = useState(null);
  const { firstName } = userDetails;

  const navigate = useNavigate();
  const notify = () => toast.success("Wallet Address Copied");
  const depositNotify = () => toast.success("Deposit Successful");

  const handleDeposit = () => {
    setLoader(true);
    const depositForm = new FormData();
    depositForm.append("image", proof[0]);
    depositForm.append("amount", amount);
    depositForm.append("type", payment);

    axios
      .post(`${baseUrl}/deposits`, depositForm, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((data) => {
        if (data.status === 200) {
          depositNotify();
          setLoader(false);
          setTimeout(() => {
            navigate("/transactions");
          }, 3000);
        }
      })
      .catch((error) => {
        setLoader(false);
      });
  };

  const toggleMethod = (wallet) => {
    if (wallet.walletName === "usdt") {
      copy(wallet.address);
      setAddress(wallet.address);
      setPayment(wallet.walletName);
    } else if (wallet.walletName === "bitcoin") {
      copy(wallet.address);
      setAddress(wallet.address);
      setPayment(wallet.walletName);
    } else if (wallet.walletName === "ethereum") {
      copy(wallet.address);
      setAddress(wallet.address);
      setPayment(wallet.walletName);
    }

    notify();
  };

  useEffect(() => {
    getAddress(token, notify);
  }, []);
  return (
    <section className="withdraw_sect deposit_sect">
      <DashboardHeader pathname={firstName} />
      <div className="withdraw_sect_hero">
        <h2>Make All Deposits Here</h2>
        <h3>Amount</h3>
        <input
          type="text"
          name="amount"
          onChange={(e) => setAmount(e.target.value)}
        />
        <h3>Click prefer Wallet Address to Deposit</h3>
        <div className="methods_sect">
          {method.map((item, index) => {
            const { walletName } = item;
            return (
              <div key={index}>
                <Button
                  background={`${payment === walletName ? "#071278" : ""}`}
                  color={`${payment === walletName ? "white" : ""}`}
                  id={index + 1}
                  action={() => toggleMethod(item)}
                  title={walletName}
                />
              </div>
            );
          })}
        </div>
        <div className="deposit_proof_sect">
          <h3>Wallet Address: {address}</h3>
          <h3>Click to Send Proof of Payment</h3>
          <input
            id="proof"
            multiple
            type="file"
            style={{ display: "none" }}
            onChange={(e) => setProof(e.target.files)}
          />
          <label
            className="btn"
            style={{ background: "#071278", color: "#fff", fontSize: 14 }}
            htmlFor="proof"
          >
            Send Proof
          </label>
        </div>
        <button onClick={handleDeposit} className="btn" id={"submit_btn"}>
          <span>Make Deposit</span>
          {loader && (
            <ColorRing
              visible={true}
              height="40"
              width="40"
              ariaLabel="blocks-loading"
              wrapperStyle={{}}
              wrapperClass="blocks-wrapper"
              colors={["#fff", "#fff", "#fff", "#fff", "#fff"]}
            />
          )}
        </button>
      </div>
      <ToastContainer />
    </section>
  );
}
export default Deposit;
