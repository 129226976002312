import React from "react";
import { AiOutlineEyeInvisible } from "react-icons/ai";
import Skeleton from "react-loading-skeleton";
import { useGlobalContext } from "../context/context";

const WalletHeader = () => {
  const { userDetails, userLoading } = useGlobalContext();
  const { profits, bonus, deposits, pendingWithdrawals } = userDetails;
  const total = profits + bonus + deposits - pendingWithdrawals;
  const symbol = "$";
  const user = JSON.parse(sessionStorage.getItem("user"));
  const otherTotal =
    user?.profits + user?.deposits + user?.bonus - user?.pendingWithdrawals;

  return (
    <div className="account_total_item_one">
      <div>
        <div className="total_balance_sect">
          {userLoading ? (
            <Skeleton width={160} height={15} />
          ) : (
            <h3 className="dashboard_h3">Your total balance</h3>
          )}
          {userLoading ? (
            <Skeleton width={25} height={15} />
          ) : (
            <AiOutlineEyeInvisible color="#10215c" size={20} />
          )}
        </div>
        <h1>
          {userLoading ? (
            <Skeleton width={300} height={50} />
          ) : (
            `${symbol}${!total ? otherTotal : total}`
          )}
        </h1>
      </div>
    </div>
  );
};

export default WalletHeader;
