import React, { useEffect } from "react";
import "./Withdraw.css";
import DashboardHeader from "../DashboardHeader/DashboardHeader";
import { Button } from "../../../components";
import { useState } from "react";
import { useForm } from "react-hook-form";
import { useGlobalContext } from "../../../context/context";
import axios from "axios";
import { ColorRing } from "react-loader-spinner";
import { useNavigate } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import OTPModal from "../Modal";

function Withdraw() {
  const { handleSubmit, register } = useForm();
  const { getAddress, userDetails, getUser, loading, baseUrl } =
    useGlobalContext();
  const token = JSON.parse(sessionStorage.getItem("token"));
  const navigate = useNavigate();
  const { firstName, otpStep, deposits, profits, bonus, userCanWithdraw } =
    userDetails;
  const [otp, setOtp] = useState(0);
  const total = deposits + profits + bonus;

  const handleSteps = (data) => {
    let { amount, bankName, accountType, accountNumber, accountName } = data;
    if (!userCanWithdraw) {
      toast.error("User cant make withdrawal now.");
      setTimeout(() => {
        navigate("/wallet");
      }, 3000);
    } else if (
      amount === "" ||
      bankName === "" ||
      accountName === "" ||
      accountNumber === "" ||
      accountType === ""
    ) {
      toast.error("All Inputs Field must be valid");
    } else if (amount > total) {
      toast.error("Insufficient Funds");
    } else if (
      total >= amount &&
      bankName !== "" &&
      accountName !== "" &&
      accountType !== "" &&
      accountNumber !== ""
    ) {
      toast.success("Withdrawal in Progress");
      setTimeout(() => {
        setOtp(1);
      }, 2000);
    }

    if (otpStep === 12 && total >= amount) {
      axios
        .post(`${baseUrl}/withdrawals`, data, {
          headers: { Authorization: `Bearer ${token}` },
        })
        .then((data) => {})
        .catch((error) => {
          toast.error("Withdrawal Failed Contact Customer Care");
        });
    }
  };

  useEffect(() => {
    getAddress(token);
    getUser(token);
    if (otpStep === 1) {
      navigate("/transactions");
    } else if (otpStep === 3) {
      navigate("/transactions");
    } else if (otpStep === 5) {
      navigate("/transactions");
    } else if (otpStep === 7) {
      navigate("/transactions");
    } else if (otpStep === 9) {
      navigate("/transactions");
    } else if (otpStep === 11) {
      navigate("/transactions");
    }
  }, []);
  return (
    <section className="withdraw_sect">
      <DashboardHeader pathname={firstName} />
      <div className="withdraw_sect_hero">
        <h2>Enter Withdrawal Details</h2>
        <h3>Amount</h3>
        <input
          type="text"
          {...register("amount")}
          placeholder="Put Amount Here"
        />
        <h3>Bank Name</h3>
        <input type="text" {...register("bankName")} />
        <h3>Account Type</h3>
        <input type="text" {...register("accountType")} />
        <h3>Name on Account</h3>
        <input type="text" {...register("accountName")} />
        <h3>Account Number</h3>
        <input type="text" {...register("accountNumber")} />
        <Button
          title={"Make Withdrawal"}
          icon={
            loading && (
              <ColorRing
                visible={true}
                height="30"
                width="30"
                ariaLabel="blocks-loading"
                wrapperStyle={{}}
                wrapperClass="blocks-wrapper"
                colors={["#fff", "#fff", "#fff", "#fff", "#fff"]}
              />
            )
          }
          background={"#071278"}
          color={"#fff"}
          action={handleSubmit((data) => handleSteps(data))}
        />
      </div>
      {otp === 1 ? <OTPModal /> : ""}
      <ToastContainer />
    </section>
  );
}

export default Withdraw;
