import React from "react";
import "./UpdateProfile.css";
import { useGlobalContext } from "../../../context/context";
import axios from "axios";
import { useForm } from "react-hook-form";
import { useState } from "react";
import { ColorRing } from "react-loader-spinner";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

function UpdateProfile({ setTab }) {
  let token = JSON.parse(sessionStorage.getItem("token"));
  const { userDetails, getUser, baseUrl } = useGlobalContext();
  const { handleSubmit, register } = useForm();
  const [loading, setLoading] = useState(false);
  const notify = () => toast.success("User Details Successfully Updated");

  const {
    firstName,
    lastName,
    gender,
    dob,
    maritalStatus,
    city,
    country,
    address,
  } = userDetails;

  const updateUserDetails = (data) => {
    setLoading(true);
    axios
      .patch(`${baseUrl}/users/user-info`, data, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then((data) => {
        if (data.status === 200) {
          notify();
          setLoading(false);
          getUser(token);
          setTimeout(() => {
            setTab(1);
          }, 3000);
        }
      })
      .catch((error) => {
        setLoading(false);
      });
  };

  return (
    <section className="update_profile">
      <h2>Update Details</h2>
      <form
        className="update_form"
        onSubmit={handleSubmit((data) => updateUserDetails(data))}
      >
        <div>
          <h3>First Name</h3>
          <input
            type="text"
            defaultValue={firstName}
            placeholder="Austin"
            {...register("firstName")}
          />
        </div>
        <div>
          <h3>Last Name</h3>
          <input
            type="text"
            defaultValue={lastName}
            placeholder="Juliet"
            {...register("lastName")}
          />
        </div>
        <div>
          <h3>City</h3>
          <input
            type="text"
            defaultValue={city}
            placeholder="Huston"
            {...register("city")}
          />
        </div>
        <div>
          <h3>Gender</h3>
          <input
            type="text"
            defaultValue={gender}
            placeholder="Male"
            {...register("gender")}
          />
        </div>
        <div>
          <h3>Marital Status</h3>
          <input
            type="text"
            defaultValue={maritalStatus}
            placeholder="single"
            {...register("maritalStatus")}
          />
        </div>
        <div>
          <h3>Address</h3>
          <input
            type="text"
            defaultValue={address}
            placeholder="Emmanuel's Street"
            {...register("address")}
          />
        </div>
        <div>
          <h3>Date of Birth</h3>
          <input
            type="text"
            defaultValue={dob}
            placeholder="20/02/2000"
            {...register("dob")}
          />
        </div>
        <div>
          <h3>Country</h3>
          <input
            type="text"
            defaultValue={country}
            placeholder="Nigeria"
            {...register("country")}
          />
        </div>
        <button className="btn">
          Update Details
          {loading && (
            <ColorRing
              visible={true}
              height="20"
              width="20"
              ariaLabel="blocks-loading"
              wrapperStyle={{}}
              wrapperClass="blocks-wrapper"
              colors={["#fff", "#fff", "#fff", "#fff", "#fff"]}
            />
          )}
        </button>
      </form>
      <ToastContainer />
    </section>
  );
}

export default UpdateProfile;
